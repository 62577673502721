











































































































































































#search-icon {
  font-size: 28px;
  margin-left: 5px;
  margin-right: 10px;
}
#cartridge-search-input {
  border: 0;
  outline: none;
  height: 100%;
  flex-grow: 1;
  margin: 0;
  font-size: 16px;
}
.card-header.border-bottom.search {
  padding: 0 10px;
  height: 55px;
}
.btn-toolbar {
  height: 100%;
  flex-grow: 1;
  align-items: center;
}
